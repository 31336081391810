import DefaultForm from "../components/form";
import Titlebar from "../components/Title.jsx";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

function Tournaments({tournamentKey}) {
    const [tournament, setTournament] = useState({});
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    


    useEffect(() => {
        const tournamentId = id || tournamentKey;

        axios
            .get(`https://server.raketefreiburg.de/api/Tournaments/${tournamentId}`)
            .then((response) => {
                setTournament(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, [tournamentKey,id]);
    return (
        <div>
            <Titlebar title={tournament.name} />
            <div className="bg-white">
                <div className="container pt-3">
                    <div className="row mt-2 justify-content-center align-items-center py-4 rounded shadow-sm mx-3 px-3">
                        <div className="col-lg-4 text-center">
                            <div>
                                <img className="rounded shadow-sm img-fluid" src={`https://server.raketefreiburg.de/uploads/${tournament.picture}`} alt={`${tournament.name}`} />
                            </div>
                        </div>
                        <div className="col-lg-8">
                                <p className="rakete-font fs-4 fw-bold text-center text-uppercase my-4">Jetzt anmelden und dabei sein!</p>
                                <div className="text-justify">
                                    <p>
                                        Unser nächstes Turnier steht vor der Tür und bietet euch die Gelegenheit, in spannenden Spielen euer Können zu zeigen. Egal ob als Spieler*in oder Zuschauer*in – bei uns ist jeder herzlich willkommen. Für Verpflegung ist gesorgt: Es gibt Essen, Getränke und auch Bier. Kommt vorbei und erlebt einen Tag voller Fußball und guter Laune!
                                    </p>
                                </div>
                        </div>
                        <div className="row justify-content-center py-3 rounded shadow-sm mx-3 px-3 align-items-center my-2">
                            <div className="text-center fs-3 fw-bold text-uppercase rakete-font rakete-rot mb-3">
                                Informationen
                            </div>
                            <div className="col text-center mx-1">
                                <div className="fs-7 rakete-rot text-uppercase fw-semibold rakete-font mt-3">Datum & Zeit</div>
                                <div className="fw-semibold my-1 rakete-font">{dayjs(tournament.date).format("DD.MM.YYYY - HH:mm")} UHR</div>
                                <div className="fs-7 text-uppercase rakete-rot fw-semibold rakete-font mt-3">Ort</div>
                                <div className="fw-semibold text-uppercase rakete-font my-1">{tournament.location}</div>
                            </div>
                            <div className="col-lg py-2 mx-1 rounded-4 shadow-sm bg-light border mt-3">
                                <div className="row justify-content-center align-items-center border-bottom">
                                    <div className="col-5 text-end py-2 fs-8 text-uppercase fw-semibold rakete-font">Mannschaften</div>
                                    <div className="col-7 ps-5 fs-7">{tournament.teams}</div>
                                </div>
                                <div className="row d-flex justify-content-center align-items-center border-bottom">
                                    <div className="col-5 text-end py-2 fs-8 text-uppercase fw-semibold rakete-font">Spieler*innen</div>
                                    <div className="col-7 ps-5 fs-7 ">{tournament.players}</div>
                                </div>
                                <div className="row d-flex justify-content-center align-items-center border-bottom">
                                    <div className="col-5 text-end py-2 fs-8 text-uppercase fw-semibold rakete-font">Modus</div>
                                    <div className="col-7 ps-5 fs-7 ">{tournament.modus === 0 ? "Liga" : tournament.modus === 1 ? "K.O. - Runde" : "Liga mit K.O.-Runde"}</div>
                                </div>
                                <div className="row d-flex justify-content-center align-items-center border-bottom">
                                    <div className="col-5 text-end py-2 fs-8 text-uppercase fw-semibold rakete-font">Teilnahmegebühr</div>
                                    <div className="col-7 ps-5 fs-7">{tournament.prize}€</div>
                                </div>
                                <div className="mx-1 pt-3 ms-3 fs-8 text-uppercase fw-semibold rakete-font rakete-rot">weitere Infos</div>
                                <p className="px-3 my-1">{tournament.info}</p>
                            </div>
                        </div>
                        <div className="text-center fs-4 fw-bold text-uppercase rakete-font mt-5">
                                Teilnahmebedingungen
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3 fw-semibold rakete-font text-uppercase text-center pt-1 mb-1">Das Wichtigste</div>
                                <div className="col-md-9 text-justify">Unser Turnier soll in erster Linie Spaß machen und auf keinen Fall unter
                                    ungesundem „Leistungsdruck“ geführt werden. D.h. alle dürfen gesunden Ehrgeiz
                                    zeigen, sollen dabei jedoch immer sportlich fair und respektvoll miteinander
                                    umgehen. So werden Verletzungen und schlechte Stimmung vermieden.</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3 fw-semibold rakete-font text-uppercase text-center pt-1 mb-1">Teilnehmerkreis</div>
                                <div className="col-md-9 text-justify">Hobby- und Betriebsteams sind herzlich willkommen <b>(keine aktiven Spieler*innen)</b>. Auch Damen- und Mixed-Teams sind ausdrücklich erwünscht! Das Mindestalter für die Teilnahme ist 16 Jahre. <b>Wichtig:</b> Jede Mannschaft sollte aus mindestens <b>{tournament.players + 3} Spieler*innen</b> bestehen, um genügend Auswechselspieler*innen zu haben.</div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-3 fw-semibold rakete-font text-uppercase text-center pt-1 mb-1">Anmeldung</div>
                                <div className="col-md-9 text-justify">Melde dich über das Formular an. Du erhältst eine E-Mail mit den Zahlungsdetails. Nach Eingang der Gebühr von <b>{tournament.prize}€</b> ist deine Anmeldung verbindlich. <b>Hinweis:</b> Die Plätze sind begrenzt – wer zuerst zahlt, hat Vorrang. Ist das Turnier bereits ausgebucht, wird dein Team automatisch als Nachrücker gesetzt, entsprechend der Reihenfolge des Zahlungseingangs. Rückt dein Team bis zum Turniertag nicht nach, erfolgt zeitnah nach dem Turnier die Rücküberweisung der Startgebühr.</div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3 fw-semibold rakete-font text-uppercase text-center pt-1 mb-1">Turnierablauf</div>
                                <div className="col-md-9 text-justify">Bitte melde deine Mannschaft eine Stunde vor Turnierbeginn, also um <b>{dayjs(tournament.date).subtract(1, 'hour').format("HH:mm")} Uhr</b>, bei der Turnierleitung an. Gespielt wird in einem <b>{tournament.modus === 0 ? "Ligasystem" : tournament.modus === 1 ? "K.O.-Runden - System" : "Vorrunden + K.O. - Rundensystem"}</b>. Den genauen Turnierplan erhältst du ca. eine Woche vor dem Turnier per Email.</div>
                            </div>
                    </div>
                    <div className="row justify-content-center mx-3 bg-light my-3 rounded shadow">
                        <div className="col-md-6 rounded my-4 me-3">
                            <p className="text-center text-uppercase rakete-font fs-2 fw-semibold rakete-rot">Turnieranmeldung</p>
                            {tournament && !loading && tournament.participants.length <= (tournament.teams * 2) && !tournament.completed ? <DefaultForm topic={3} data={tournament._id}/> : !tournament.completed ? <div className="text-center my-3 text-secondary rakete-font fs-5 text-uppercase">Keine Plätze verfügbar</div> : <div className="text-center my-3 text-secondary rakete-font fs-5 text-uppercase">Anmeldung demnächst möglich</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tournaments;