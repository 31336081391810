import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from './components/Navbar.jsx';
import Footer from './components/Footer.jsx';
import Header from './components/Header.jsx';
import Home from './pages/Home.jsx';
import Club from './pages/Club.jsx';
import Event from './pages/Events.jsx';
import Member from './pages/Member.jsx';
import Datenschutz from './pages/Datenschutz.jsx';
import Impressum from './pages/Impressum.jsx';
import Erste from "./pages/Erste.jsx";
import Zweite from "./pages/Zweite.jsx";
import Mixed from "./pages/Mixed.jsx";
import Frauen from "./pages/Frauen.jsx";
import ScrollToTop from "./components/ScrollToTop.js";
import Kontakt from "./pages/Kontakt.jsx";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Sponsoring from "./pages/Sponsoring.jsx";
import Tournaments from "./pages/tournaments.jsx";
import axios from "axios";
import PollLogin from "./pages/PollLogin.jsx";

function App() {
  useEffect(() => {
    const fetchVisit = async () => {
      try {
        await axios.get('https://server.raketefreiburg.de/visit');
      } catch (error) {
        console.error('Server nicht erreichbar. Bitte versuchen Sie es später noch einmal.');
      }
    };

    fetchVisit();
  }, []);
  return (
    <div className="App max-size mx-auto shadow">
      <ScrollToTop />
      <ToastContainer />
      <NavBar />
      <Header />
      <Routes>
        <Route path="poll" element={<PollLogin />} />
        <Route path="*" element={<Home />} />
        <Route index element={<Home />} />
        <Route path="club" element={<Club />} />
        <Route path="events" element={<Event />} />
        <Route path="erste" element={<Erste />} />
        <Route path="zweite" element={<Zweite />} />
        <Route path="mixed" element={<Mixed />} />
        <Route path="frauen" element={<Frauen />} />
        <Route path="member" element={<Member />} />
        <Route path="datenschutz" element={<Datenschutz />} />
        <Route path="impressum" element={<Impressum />} />
        <Route path="kontakt" element={<Kontakt />} />
        <Route path="sponsor" element={<Sponsoring />} />
        <Route path="tournament/:id" element={<Tournaments />} />
        <Route path="raketencup" element={<Tournaments tournamentKey="67308708eada848f842dedfa" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
